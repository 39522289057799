<template>
  <el-dialog title="购买" :visible.sync="buyBox" width="40%" :before-close="handleClose">
    <div class="buyBox">
      <div class="imgBox">
        <img v-if="type === 'gaode'" src="@/assets/buyGaode.png" alt="" />
        <img v-else src="@/assets/buyHuawei.png" alt="" />
      </div>
      <div class="right">
        <div class="title">套餐类型</div>
        <div
          :style="{ backgroundColor: activeProductlist === index ? '#fff1e7' : '', border: activeProductlist === index ? '1px solid #eba37c' : '1px solid #e7e2d9' }"
          class="ProductItem"
          v-for="(item, index) in Productlist"
          :key="index"
          @click="activeProductlist = index"
        >
          <span
            ><b>{{ item.name }}</b></span
          >
          <span
            ><b :style="{ fontSize: '20px', color: activeProductlist === index ? '#fc5325' : '' }">¥{{ item.money }}</b></span
          >
        </div>
        <div class="title">支付方式</div>
        <div class="payClassBox">
          <div @click="choosePayStyle(2)" :class="[payStyle == 2 ? 'isActive' : '']">
            <img class="jiao" v-show="payStyle == 2" src="@/assets/image/jiao.png" alt="" />
            <img style="margin-right: 5px" src="@/assets/image/ali.png" alt="" />
            支付宝支付
          </div>
          <div @click="choosePayStyle(1)" :class="[payStyle == 1 ? 'isActive' : '']">
            <img class="jiao" v-show="payStyle == 1" src="@/assets/image/jiao.png" alt="" />
            <img style="margin-right: 5px" src="@/assets/image/wx.png" alt="" />
            微信支付
          </div>
        </div>
        <div style="display: flex; align-items: center; justify-content: center; margin-top: 25px">
          <div class="payQrCode">
            <div id="qrcode" ref="qrcode"></div>
            <div class="payMask" v-show="payFlag">
              <i class="el-icon-check"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import QRCode from 'qrcodejs2'
let orderTime = null
export default {
  props: {
    buyBox: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      aliCode: '',
      wxCode: '',
      Productlist: [],
      activeProductlist: 0,
      lastActive: null,
      payStyle: 2,
      lastPayStyle: null,
      orderID: 0
    }
  },
  methods: {
    tuBuy() {
      let that = this
      this.$axios
        .post(this.$api.set.buyGorH.Productlist, {
          type: this.type === 'gaode' ? 1 : this.type === 'huawei' ? 2 : ''
        })
        .then((res) => {
          if (res.code === 0) {
            that.Productlist = res.result
            this.createdOrder()
          }
        })
    },
    // 选择支付方式
    choosePayStyle(index) {
      this.payStyle = index
    },
    backFn() {
      this.$axios
        .post(this.$api.set.buyGorH.getOrderStatus, {
          id: this.orderID
        })
        .then((res) => {
          if (res.code === 0) {
            if (res.result.status == 1) {
              this.$message.success(`${this.payStyle == 1 ? '微信' : '支付宝'}支付成功`)
              this.buyBox = false
              clearInterval(orderTime)
            }
          }
        })
    },
    createQr(statsu) {
      this.$refs.qrcode.innerHTML = ''
      let qr_code = ''
      if (statsu == 1) {
        qr_code = this.wxCode
      } else {
        qr_code = this.aliCode
      }
      let qrcode = new QRCode('qrcode', {
        width: 132,
        height: 132,
        text: qr_code, // 二维码地址
        colorDark: '#000',
        colorLight: '#fff'
      })
    },
    // 创建订单
    createdOrder() {
      let that = this
      if (this.activeProductlist != this.lastActive || this.payStyle != this.lastPayStyle) {
        that.$axios
          .post(this.$api.set.buyGorH.createOrder, {
            product_id: this.Productlist[this.activeProductlist].id,
            pay_type: this.payStyle
          })
          .then((res) => {
            if (res.code === 0) {
              if (this.payStyle === 1) {
                // wx 返回
                that.orderID = res.result.id
                that.wxCode = res.result.data
              } else {
                // 支付宝返回
                that.orderID = res.result.id
                that.aliCode = res.result.data.qr_code
              }
              orderTime = setInterval(that.backFn, 1000)
              that.createQr(this.payStyle)
              that.lastActive = that.activeProductlist
              that.lastPayStyle = that.payStyle
            }
          })
      }
    }
  },
  watch: {
    buyBox: {
      handler(val) {
        if (!val) {
          this.$emit('closeBuy')
          if (orderTime) clearInterval(orderTime)
        } else {
          this.tuBuy()
        }
      },
      immediate: true
    },
    activeProductlist: {
      handler() {
        if (orderTime) clearInterval(orderTime)
        this.createdOrder()
      }
    },
    payStyle: {
      handler() {
        if (orderTime) clearInterval(orderTime)
        this.createdOrder()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.buyBox {
  display: flex;
  justify-content: space-between;
  .imgBox {
    width: 50%;

    img {
      width: 100%;
    }
  }
  .right {
    width: 48%;
    .title {
      margin: 20px 0;
      font-size: 18px;
      font-weight: bold;
    }
    .ProductItem {
      width: 100%;
      padding: 10px 40px;
      border: 1px solid #e7e2d9;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .payClassBox {
      display: flex;
      & > div {
        width: 170px;
        height: 50px;
        border: 1px solid #e4e4e4;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        cursor: pointer;
        margin-right: 10px;
        position: relative;
        .jiao {
          position: absolute;
          z-index: 2;
          right: 0;
          bottom: 0;
          width: 24px;
          height: 20px;
          margin: 0;
        }
      }
      .isActive {
        border: 2px solid red;
      }
    }
  }
  .payQrCode {
    margin-top: 10px;
    width: 132px;
    height: 132px;
    position: relative;
    img {
      width: 100%;
    }
    .payMask {
      position: absolute;
      top: 0;
      left: 0;
      width: 132px;
      height: 132px;
      background: rgba(0, 0, 0, 0.3);
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 70px;
        font-weight: bold;
        color: #2fb72f;
      }
    }
  }
}
</style>
